<div class="detail" [class.visible]="transaction" [class.shoved]="transaction && conversationActive">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Transaction</h2>
  </header>
  <section>
    <div *ngIf="!transaction" class="loading">Loading . . .</div>
    <div *ngIf="transaction">
      <div class="context">
        <div class="row">
          <div class="label">Transaction ID:</div>
          <div class="value">{{transaction._id}}</div>
        </div>
        <div class="row">
          <div class="label">Type:</div>
          <div class="value capitalize">{{types[transaction.type].name}}</div>
        </div>
        <div *ngIf="transaction.subtype" class="row">
          <div class="label">Subtype:</div>
          <div class="value capitalize">{{transaction.subtype}}</div>
        </div>
        <div *ngIf="transaction.accountName" class="row">
          <div class="label">Account:</div>
          <div class="value">{{transaction.accountName}}</div>
        </div>
        <div *ngIf="transaction.type == 'inventory' && transaction.name" class="row">
          <div class="label">Item:</div>
          <div class="value">
            <a (click)="loadDetail('inventory', transaction.accountId)">{{transaction.name}}</a>
          </div>
        </div>

        <div *ngIf="transaction.lastFour" class="row" (click)="showAccountDetails()">
          <div *ngIf="transaction.type != 'reward'" class="label">Card #</div>
          <div *ngIf="transaction.type == 'reward'" class="label">Code</div>
          <div class="value" *ngIf="!transaction.fullCardNumber && !transaction.cardNumberLoading">{{transaction.lastFour | rf: 'cardNumber'}}</div>
          <div class="value" *ngIf="transaction.cardNumberLoading">Loading . . .</div>
          <div class="value" *ngIf="transaction.fullCardNumber">{{transaction.fullCardNumber}}</div>
        </div>

        <div *ngIf="transaction.checkNum" class="row">
          <div class="label">Check #</div>
          <div class="value">{{transaction.checkNum}}</div>
        </div>

        <div *ngIf="transaction.firstName" class="row">
          <div class="label">First Name:</div>
          <div class="value">{{transaction.firstName}}</div>
        </div>
        <div *ngIf="transaction.lastName" class="row">
          <div class="label">Last Name:</div>
          <div class="value">{{transaction.lastName}}</div>
        </div>

        <div class="row" *ngIf="transaction.type == 'inventory'">
          <div class="label">Qty:</div>
          <div class="value">{{transaction.amount | rf: 'number'}}</div>
        </div>
        <div class="row" *ngIf="transaction.type != 'inventory'">
          <div class="label">Amount:</div>
          <div class="value">{{transaction.amount | rf: 'currencyInverse'}}</div>
        </div>
        <div class="row" *ngIf="transaction.change">
          <div class="label">Change:</div>
          <div class="value">{{transaction.change | rf: 'currency'}}</div>
        </div>

        <div class="row" *ngIf="transaction.type == 'inventory'">
          <div class="label">End Balance:</div>
          <div class="value">{{transaction.endBalance | rf: 'number'}}</div>
        </div>

        <div class="row" *ngIf="transaction.amount == 0">
          <div class="label">Attempted Amount:</div>
          <div class="value">{{transaction.attemptedAmount | rf: 'currencyInverse'}}</div>
        </div>
        <div *ngIf="transaction.status && transaction.type != 'cash'" class="row" (click)="toggleStatusDetails()">
          <div class="label">Status:</div>
          <div class="value">{{transaction.status}}</div>
        </div>

        <div *ngIf="showStatusDetails && transaction.responseDetails && transaction.responseDetails.avsResultCode" class="row">
          <div class="label">Address Validation:</div>
          <div class="value">{{transaction.responseDetails.avsResultDescription}}</div>
        </div>
        <div *ngIf="showStatusDetails && transaction.responseDetails && transaction.responseDetails.cscResultCode" class="row">
          <div class="label">Security Code:</div>
          <div class="value">{{transaction.responseDetails.cscResultDescription}}</div>
        </div>

        <div *ngIf="!transaction.success && transaction.response" class="row">
          <div class="label">Error</div>
          <div class="value">{{transaction.response}}</div>
        </div>

        <div *ngIf="transaction.reason" class="row">
          <div class="label">Reason:</div>
          <div class="value">{{transaction.reason}}</div>
        </div>
        <div *ngIf="transaction.customerId" class="row">
          <div class="label">Customer:</div>
          <div class="value">
            <a *ngIf="transaction.customerId" (click)="loadDetail('customer', transaction.customerId)">{{transaction.customerName}}</a>
          </div>
        </div>
        <div class="row">
          <div class="label">Location:</div>
          <div class="value">{{transaction.locationName}}</div>
        </div>
        <div *ngIf="transaction.userName" class="row">
          <div class="label">User:</div>
          <div class="value">
            <!-- <a (click)="loadDetail('user', transaction.userId)">{{transaction.userName}}</a> -->
            {{transaction.userName}}
          </div>
        </div>
        <div class="row">
          <div class="label">Date:</div>
          <div class="value">{{transaction.time | rf: 'dateTime'}}</div>
        </div>
        <div *ngIf="transaction.note" class="row">
          <div class="label">Note:</div>
          <div class="value capitalize">{{transaction.note}}</div>
        </div>

        <div *ngIf="transaction.purchaseId" class="row">
          <ng-container *ngIf="transaction.purchaseType != 'vendor-retail' && transaction.purchaseType != 'retail-vendor' && transaction.purchaseType != 'retail-retail' && transaction.purchaseType != 'adjustment-retail'">
          <div class="label">Purchase:</div>
          <div class="value">
            <a (click)="loadDetail('purchase', transaction.purchaseId)">{{transaction.purchaseKey}}</a>
          </div>
          </ng-container>

          <ng-container *ngIf="transaction.purchaseType == 'vendor-retail' || transaction.purchaseType == 'retail-vendor' || transaction.purchaseType == 'retail-retail' || transaction.purchaseType == 'adjustment-retail'">
            <div class="label">Transfer:</div>
            <div class="value">
              <a (click)="viewTransfer(transaction.purchaseId)">{{transaction.purchaseKey}}</a>
            </div>
          </ng-container>

        </div>
        <div *ngIf="transaction.relatedTransactionId" class="row">
          <div class="label">Related Trans:</div>
          <div *ngFor="let relatedTransactionId of transaction.relatedTransactionId" class="value">
            <a (click)="loadDetail('transaction', relatedTransactionId)">{{relatedTransactionId}}</a>
          </div>
        </div>
      </div>

      <div *ngIf="transaction.email" class="description">
        <div class="label">Email:</div>
        <div class="description-text">{{transaction.email}}</div>
      </div>

      <div *ngIf="transaction.description" class="description">
        <div class="label">Description:</div>
        <div class="description-text">{{transaction.description | rf:'decodeEntities'}}</div>
      </div>

      <div *ngIf="transaction.billing" class="address">
        <div class="label">Billing:</div>
        <div>{{transaction.billing.firstName}} {{transaction.billing.lastName}}</div>
        <div>{{transaction.billing.companyName}}</div>
        <div>{{transaction.billing.streetAddress}}</div>
        <div>{{transaction.billing.streetAddressExt}}</div>
        <div>{{transaction.billing.addressLocality}}, {{transaction.billing.addressRegion}} {{transaction.billing.postalCode}}</div>
      </div>

      <div *ngIf="transaction.shipping" class="address">
        <div class="label">Shipping:</div>
        <div>{{transaction.shipping.firstName}} {{transaction.shipping.lastName}}</div>
        <div>{{transaction.shipping.companyName}}</div>
        <div>{{transaction.shipping.streetAddress}}</div>
        <div>{{transaction.shipping.streetAddressExt}}</div>
        <div>{{transaction.shipping.addressLocality}}, {{transaction.shipping.addressRegion}} {{transaction.shipping.postalCode}}</div>
      </div>

      <div *ngIf="transaction.accountTransactions" class="transactions">
        <div class="label">Account Transactions</div>
        <div *ngFor="let aTrans of transaction.accountTransactions" class="transaction" (click)="loadDetail('transaction', aTrans._id)">
          <div class="amount">{{aTrans.amount | rf:'currency'}}</div>
          <div class="time">{{aTrans.time | rf:'dateTime'}}</div>
        </div>
      </div>

      <div class="controls">
        <button *ngIf="transaction.type == 'gift'" class="button" (click)="viewHistory()">View Card History</button>

        <button *ngIf="transaction.canVoid" class="button" (click)="voidClick()">Void</button>

        <button *ngIf="transaction.type === 'credit' && (transaction.status === 'pending' || transaction.response == 'Transaction canceled due to timeout')" class="button yellow" (click)="checkPaymentStatus()">Check Status</button>

        <div *ngIf="transaction.canSendReceipt" class="receipt">
          <input type="email" [(ngModel)]="transaction.email" placeholder="Receipt Email" />
          <button class="button" [class.sending]="sendingReceipt" (click)="sendReceipt()">Send Receipt</button>
        </div>

        <button *ngIf="transaction.canRefund && !refundAmount" class="button red" (click)="refundClick()">Refund</button> <!-- && !transaction.purchaseId -->

        <button *ngIf="transaction.canNegate" class="button red" (click)="negateTransaction()">Negate Inventory Transaction</button>

        <ng-container *ngIf="transaction.canSendInvoice && transaction.type == 'invoice'">
          <button class="button" (click)="openInvoiceExternally()">View Invoice</button>
          <button class="button" (click)="copyInvoiceLink()" [class.copied]="invoiceLinkCopied">{{invoiceLinkCopyText}}</button>
          <button *ngIf="transaction.status == 'pending'" class="button" (click)="resendInvoice()">Resend</button>
          <button *ngIf="transaction.status == 'pending'" class="button red" (click)="cancelInvoice()">Cancel Invoice</button>
        </ng-container>

      </div>
      <div *ngIf="refundAmount && !refundRequestAmount" class="refund-controls">
        <label>Refund Amount</label>
        $<input type="number" [(ngModel)]="refundAmount" (keyup)="verifyRefundAmount()" (click)="verifyRefundAmount()" />
        <button class="button red" (click)="refundRequest()">Refund Now</button>
      </div>
      <div *ngIf="refundRequestAmount" class="refund-controls">
        <div class="confirm-dialog">Are you sure you would like to refund ${{refundRequestAmount}}?</div>
        <button class="button" (click)="refund()">Yes</button>
        <button class="button" (click)="refundCancel()">No</button>
      </div>
    </div>
  </section>
</div>
