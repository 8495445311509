import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

// import { Fuse } from 'fuse.js';
import * as Fuse from 'fuse.js';

import { AuthorizationService } from '../authorization/authorization.service';
import { IoService } from '../io/io.service';
import { ScreenService } from '../screen/screen.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private activeLocation;
  private locationOptions;
  private locations;
  private indexedLocations;
  private allLocation = {
    name: 'All Locations',
    _id: null
  };
  private searchText;

  private activeScreen;

  private activeLocationSet = new Subject<any>();
  private locationsSet = new Subject<any>();

  private activeScreenSet: Subscription;

  constructor(
    private authorizationService: AuthorizationService,
    private ioService: IoService,
    private screenService: ScreenService,
    private authenticationService: AuthenticationService
  ) {
    // this.loadLocations().then(() => {});
    this.authenticationService.authenticationStateChangedObservable().subscribe(authenticatedId => {
      if (authenticatedId) {
        this.loadLocations();
      }
    });
    if (this.authenticationService.isSignedIn()) {
      this.loadLocations();
    }
  }

  async loadLocations(): Promise<void> {
    return new Promise<void>( (resolve) => {
      this.ioService.post('/location/getActiveUserLocations').then((locationsResponse: any) => {
        this.locationOptions = locationsResponse;

        this.indexedLocations = {};
        for (let location of this.locationOptions) {
          this.indexedLocations[location._id] = location;
        }

        // Get the active screen
        this.activeScreen = this.screenService.getActiveScreen();

        // Filter now
        this.filterLocations();

        // Listen for when the screen changes
        this.activeScreenSet = this.screenService.activeScreenSetObservable().subscribe(activeScreen => {
          this.activeScreen = activeScreen;
          // this.filterLocations(activeScreen);
          this.filterLocations();
        });
        resolve();
      });
    });
  }

  filterLocations() {
    this.locations = [];

    if (this.activeScreen) {
      if (this.activeScreen.locationAll) {
        this.locations.push(this.allLocation);
      }

      for (let location of this.locationOptions) {
        if ((!this.activeScreen.locationPermission) || (this.authorizationService.checkPermission(this.activeScreen.locationPermission, location._id))) {
          this.locations.push(location);
        }
      }
    }

    // If the active location is not in the valid set, select the first valid location
    if ((this.locations.indexOf(this.activeLocation) == -1) || (!this.activeLocation)) {
      this.setActiveLocation(this.locations[0]);
    }

    // If there is only one valid location and it is not the active location, select it
    let retailLocations = this.locations.filter(location => {
      return location.type === 'retail';
    });
    if ((retailLocations.length == 1) && (retailLocations[0] != this.activeLocation)) {
      this.setActiveLocation(retailLocations[0]);
    }

    // Apply Search
    if (this.searchText) {
      let fuse = new Fuse(this.locations, {
        shouldSort: true,
        threshold: 0.3,
        location: 0,
        distance: 100,
        maxPatternLength: 16,
        minMatchCharLength: 1,
        keys: [
          'name'
        ]
      });

      this.locations = fuse.search(this.searchText);
    }

    this.locationsSet.next(this.locations);
  }

  setSearchText(searchText) {
    this.searchText = searchText;
    this.filterLocations();
  }

  getSearchText() {
    return this.searchText;
  }

  setActiveLocation(location) {
    this.activeLocation = location;
    this.activeLocationSet.next(this.activeLocation);
  }

  getActiveLocation() {
    return this.activeLocation;
  }

  getActiveLocationId() {
    return this.activeLocation._id;
  }

  getLocations() {
    return this.locations;
  }

  getLocationIds() {
    return this.getLocations().map(location => {
      return location._id;
    });
  }

  async getLocationsByType(type): Promise<Array<any>> {
    if ( !this.locations ) {
      await this.loadLocations();
    }
    return this.locations.filter((location) => {
      return location.type === type;
    });
  }

  async getLocationsObjectByType(type) {
    let locations = await this.getLocationsByType(type);
    let locationsObject = {};
    for (let location of locations) {
      locationsObject[location._id] = location;
    }
    return locationsObject;
  }

  getLocation(locationId) {
    if (this.indexedLocations && this.indexedLocations[locationId]) {
      return this.indexedLocations[locationId];
    } else {
      return {
        name: 'Unknown Location'
      };
    }
  }

  activeLocationSetObservable() : Observable<any> {
    return this.activeLocationSet.asObservable();
  }

  locationsSetObservable(): Observable<any> {
    return this.locationsSet.asObservable();
  }

}
