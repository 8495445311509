<div class="detail" [class.visible]="purchase" [class.shoved]="purchase && conversationActive">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Purchase</h2>
  </header>
  <section>
    <div *ngIf="!purchase" class="loading">Loading . . .</div>
    <div *ngIf="purchase">

      <div class="context">
        <div class="row">
          <div class="label">Purchase ID:</div>
          <div class="value">{{purchase._id}}</div>
        </div>
        <div class="row">
          <div class="label">Check #:</div>
          <div class="value">{{purchase.key}}</div>
        </div>
        <div class="row">
          <div class="label">Status:</div>
          <div class="value">{{purchase.status}}</div>
        </div>
        <div class="row">
          <div class="label">User:</div>
          <div class="value">{{purchase.userName}}</div>
        </div>
        <div *ngIf="purchase.customerId" class="row">
          <div class="label">Customer:</div>
          <div class="value">
            <a *ngIf="purchase.customerId" (click)="loadDetail('customer', purchase.customerId)">{{purchase.customerName}}</a>
          </div>
        </div>
        <div class="row">
          <div class="label">Location:</div>
          <div class="value">{{purchase.locationName}}</div>
        </div>
        <div class="row">
          <div class="label">Date:</div>
          <div class="value">{{purchase.timeAdded | rf: 'dateTime'}}</div>
        </div>

        <div *ngIf="purchase.event" class="row">
          <div class="label">Event:</div>
          <div class="value">{{purchase.event.name | rf: 'decodeEntities'}}</div>
        </div>
        <div *ngIf="purchase.event" class="row">
          <div class="label">Event Date:</div>
          <div class="value">{{purchase.event.start | rf: 'dateTime'}}</div>
        </div>

      </div>


      <div *ngIf="purchase.billTo" class="address">
        <div class="label">Billing:</div>
        <div>{{purchase.billTo.firstName}} {{purchase.billTo.lastName}}</div>
        <div>{{purchase.billTo.companyName}}</div>
        <div>{{purchase.billTo.address.streetAddress}}</div>
        <div>{{purchase.billTo.address.streetAddressExt}}</div>
        <div>{{purchase.billTo.address.addressLocality}}, {{purchase.billTo.address.addressRegion}} {{purchase.billTo.address.postalCode}}</div>
        <div>{{purchase.billTo.email}}</div>
      </div>

      <div *ngIf="purchase.shipTo" class="address">
        <div class="label">Shipping:</div>
        <div>{{purchase.shipTo.firstName}} {{purchase.shipTo.lastName}}</div>
        <div>{{purchase.shipTo.companyName}}</div>
        <div>{{purchase.shipTo.address.streetAddress}}</div>
        <div>{{purchase.shipTo.address.streetAddressExt}}</div>
        <div>{{purchase.shipTo.address.addressLocality}}, {{purchase.shipTo.address.addressRegion}} {{purchase.shipTo.address.postalCode}}</div>
        <div>{{purchase.shipTo.email}}</div>
      </div>




      <ng-template #recursivePurchase let-purchaseItem="purchaseItem" let-multiplier="multiplier" let-depth="depth">
        <div class="item-row">
          <div *ngIf="purchaseItem.qty != 1" class="item-qty">{{purchaseItem.qty}}&nbsp;x&nbsp;</div>
          <div class="item-name">{{purchaseItem.name}}</div>
          <div *ngIf="purchaseItem.price" class="item-price">{{purchaseItem.price*multiplier*purchaseItem.qty | rf:'currency'}}</div>
        </div>

        <div *ngIf="purchaseItem.unitDescription" class="unit-description">
          {{purchaseItem.unitDescription}}
        </div>

        <ng-container *ngIf="purchaseItem.options">
          <ng-container *ngFor="let optionGroup of purchaseItem.options | keyvalue">
            <div *ngIf="optionGroup.value.selected && optionGroup.value.selected > 0" class="option-group">
              <div class="option-group-name">{{optionGroup.value.name}}</div>
              <ng-container *ngFor="let suboption of optionGroup.value.options | keyvalue">
                <ng-container *ngIf="suboption.value.qty && suboption.value.qty > 0">
                  <ng-container *ngTemplateOutlet="recursivePurchase; context: { purchaseItem: suboption.value, multiplier: multiplier*purchaseItem.qty, depth: depth+1 }"></ng-container>
                </ng-container>
              </ng-container>

            </div>
          </ng-container>
        </ng-container>

        <div *ngIf="purchaseItem.discountsArray" class="discounts">
          <div *ngFor="let discount of purchaseItem.discountsArray" class="discount">
            <div class="discount-name">{{discount.name}}</div>
            <div class="discount-amount">{{discount.value | rf: 'currencyInverse'}}</div>
          </div>
        </div>

      </ng-template>


      <div class="recursive-purchase">
        <div class="item-rows">
          <div *ngFor="let item of purchase.items" (click)="loadDetail('inventory', item.accountId)" class="purchase-item-row">
            <ng-container *ngTemplateOutlet="recursivePurchase; context: { purchaseItem: item, multiplier: 1, depth: 0 }"></ng-container>

            <div *ngIf="item.note && item.note.length > 0">
              <div class="option-cart">
                <div class="option-group-name">Notes</div>
                <div *ngFor="let note of item.note" class="value">&bull;{{note}}</div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="totals">
        <div *ngIf="purchase.subtotal" class="row">
          <div class="label">Subtotal</div>
          <div class="value">{{purchase.subtotal | rf: 'currency'}}</div>
        </div>
        <div *ngIf="purchase.discount" class="row">
          <div class="label">Discount</div>
          <div class="value">{{purchase.discount | rf: 'currency'}}</div>
        </div>
        <div *ngIf="purchase.shippingTotal" class="row">
          <div class="label">Shipping</div>
          <div class="value">{{purchase.shippingTotal | rf: 'currency'}}</div>
        </div>
        <div *ngIf="purchase.taxes" class="row">
          <div class="label">Tax</div>
          <div class="value">{{purchase.taxes | rf: 'currency'}}</div>
        </div>
        <div *ngIf="purchase.shipping" class="row">
          <div class="label">Shipping</div>
          <div class="value">{{purchase.shipping | rf: 'currency'}}</div>
        </div>
        <div *ngIf="purchase.tip" class="row">
          <div class="label">Tip</div>
          <div class="value">{{purchase.tip | rf: 'currency'}}</div>
        </div>
        <div class="row">
          <div class="label">Total</div>
          <div class="value">{{purchase.total | rf: 'currency'}}</div>
        </div>
      </div>

      <div *ngIf="purchase.transactions[0]" class="transactions">
        <div class="row">
          <div class="label">Payments</div>
        </div>
        <div *ngFor="let transaction of purchase.transactions" class="row transaction" [class.canceled]="transaction.status == 'canceled'">

          <ng-container *ngIf="transaction.type != 'discount'">
            <div class="label">
              <button class="button button-left" (click)="loadDetail('transaction', transaction._id)">View</button>
              <a (click)="loadDetail('transaction', transaction._id)">
                <ng-container *ngIf="transaction.ledgerName">{{transaction.ledgerName}}</ng-container>
                <ng-container *ngIf="!transaction.ledgerName">{{transaction.cardType}}</ng-container>

                <div *ngIf="transaction.accountName">{{transaction.accountName}}</div>
                <ng-container *ngIf="!transaction.accountName">
                  {{transaction.cardNumber | rf: 'cardNumber'}}
                  {{transaction.lastFour | rf: 'cardNumber'}}
                  {{transaction.checkNum}}
                </ng-container>

              </a>
            </div>
            <div *ngIf="transaction.type != 'invoice'" class="value">{{transaction.amount | rf: 'currency'}}</div>
          </ng-container>

        </div>
      </div>

      <div class="totals">
        <div class="row">
          <div class="label">Due</div>
          <div class="value">{{purchase.due | rf: 'currency'}}</div>
        </div>
      </div>

      <div *ngIf="purchase.note" class="notes">
        <div *ngFor="let note of purchase.note">{{note | rf: 'htmlString'}}</div>
      </div>


      <div *ngIf="purchase.fulfillments && purchase.fulfillments.length > 0" class="transactions">
        <div class="row">
          <div class="label">Gift Card Activations</div>
        </div>
        <div *ngFor="let fulfillment of purchase.fulfillments" class="row transacton" [class.unfulfilled]="!fulfillment.fulfilled">
          <div class="label">
            <a (click)="loadDetail('transaction', fulfillment.transactionId)">
              {{fulfillment.lastFour | rf: 'cardNumber'}}
            </a>
          </div>
          <div class="value">{{fulfillment.amount | rf: 'currency'}}</div>
        </div>
      </div>


      <div class="controls">
        <!-- <button *ngIf="purchase.status == 'open' && permissions.canEdit && !purchase.pendingTransactions" class="button" routerLink="/purchase/{{purchase._id}}">Edit Purchase</button> -->
        <!-- <button *ngIf="purchase.status == 'open' && purchase.due == purchase.total && !purchase.pendingTransactions && permissions.canCancel" class="button red" (click)="cancelPurchase()">Cancel Purchase</button> -->

        <div *ngIf="purchase.status == 'complete' && permissions.canSendReceipt" class="receipt">
          <input type="email" [(ngModel)]="purchase.email" placeholder="Receipt Email" />
          <button class="button" [class.sending]="sendingReceipt" (click)="sendReceipt()">Send Receipt</button>
          <div class="receipt-text">{{receiptText}}</div>
        </div>

      </div>







      <div *ngIf="purchase.taxTransactions[0]" class="transactions">
        <div class="row">
          <div class="label">Tax Transactions</div>
        </div>
        <div *ngFor="let transaction of purchase.taxTransactions" class="row transaction transaction-detail" [class.canceled]="transaction.status == 'canceled'">

          <ng-container>
            <div class="label">
              <a (click)="loadDetail('transaction', transaction._id)">
                {{transaction.name}}
              </a>
            </div>
            <div class="value">{{transaction.amount | rf: 'currency'}}</div>
          </ng-container>

        </div>
      </div>


      <div *ngIf="purchase.discountTransactions[0]" class="transactions">
        <div class="row">
          <div class="label">Discount Transactions</div>
        </div>
        <div *ngFor="let transaction of purchase.discountTransactions" class="row transaction transaction-detail" [class.canceled]="transaction.status == 'canceled'">

          <ng-container>
            <div class="label">
              <a (click)="loadDetail('transaction', transaction._id)">
                {{transaction.name}}
              </a>
            </div>
            <div class="value">{{transaction.amount | rf: 'currency'}}</div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="purchase.inventoryTransactions[0]" class="transactions">
        <div class="row">
          <div class="label">Inventory Transactions</div>
        </div>
        <div *ngFor="let transaction of purchase.inventoryTransactions" class="row transaction transaction-detail" [class.canceled]="transaction.status == 'canceled'">

          <ng-container>
            <div class="label">
              <a (click)="loadDetail('transaction', transaction._id)">
                {{transaction.name}}
              </a>
            </div>
            <div class="value">{{transaction.amount | rf: 'number'}}</div>
          </ng-container>
        </div>
      </div>

      <div class="controls">
        <!-- <button class="button red" *ngIf="permissions.canDelete" (click)="deletePurchase()">Delete Purchase</button> -->
        <button class="button red" *ngIf="permissions.canArchive" (click)="archivePurchase()">Archive Purchase</button>
        <button class="button green" *ngIf="permissions.canComplete" (click)="completePurchase()">Complete Purchase</button>
      </div>

    </div>
  </section>
</div>
