import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { NgxMaskModule } from 'ngx-mask';

import { TransferComponent } from './transfer.component';
import { AdvancedImportComponent } from './advanced-import/advanced-import.component';



@NgModule({
  declarations: [
    TransferComponent,
    AdvancedImportComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMaskModule.forRoot()
  ]
})
export class TransferModule { }
