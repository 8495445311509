<div class="detail" [class.visible]="vendor">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2>Vendor</h2>
  </header>
  <section>
    <div *ngIf="!vendor" class="loading">Loading . . .</div>
    <div *ngIf="vendor">
      <div>

        <div class="input-set input-set-name">
          <div>
            <label for="name">Name</label>
            <input type="text" id="name" [(ngModel)]="vendor.name" />
          </div>
        </div>

        <div class="input-set input-set-address">
          <div>
            <label for="streetAddress">Address</label>
            <input type="text" id="streetAddress" [(ngModel)]="vendor.address.streetAddress" />
          </div>
          <div>
            <label for="streetAddressExt">Address 2</label>
            <input type="text" id="streetAddressExt" [(ngModel)]="vendor.address.streetAddressExt" />
          </div>
          <div>
            <label for="addressLocality">City</label>
            <input type="text" id="addressLocality" [(ngModel)]="vendor.address.addressLocality" />
          </div>
          <div *ngIf="[undefined, null, '', 'US'].indexOf(vendor.address.addressCountry) != -1">
            <label for="addressRegion">State</label>
            <select [(ngModel)]="vendor.address.addressRegion">
              <option *ngFor="let state of states" [value]="state.code">{{state.name}}</option>
            </select>
          </div>
          <div *ngIf="[undefined, null, '', 'US'].indexOf(vendor.address.addressCountry) == -1">
            <label for="addressRegion">State / Province / Region</label>
            <input type="text" id="addressRegion" [(ngModel)]="vendor.address.addressRegion" />
          </div>
          <div>
            <label for="postalCode">Zip / Postal Code</label>
            <input type="text" id="postalCode" [(ngModel)]="vendor.address.postalCode" mask="00000" />
          </div>
          <div>
            <label for="country">Country</label>
            <select [(ngModel)]="vendor.address.addressCountry">
              <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
            </select>
          </div>
          <!-- <div>
            <label for="timeZone">Time Zone</label>
            <select [(ngModel)]="vendor.timeZone">
              <option *ngFor="let timeZone of timeZoneOptions" [value]="timeZone.value">{{timeZone.name}}</option>
            </select>
          </div> -->
        </div>

        <div class="input-set input-set-contact">
          <div>
            <label for="website">Website</label>
            <input type="text" id="website" [(ngModel)]="vendor.website" />
          </div>
          <div>
            <label for="email">Email</label>
            <input type="email" id="email" [(ngModel)]="vendor.email" />
          </div>
          <div>
            <label for="telephone">Phone</label>
            <input type="tel" id="telephone" [(ngModel)]="vendor.telephone" mask="0 (000) 000-0000" />
          </div>
        </div>

        <div class="input-set input-set-contact">
          <div>
            <label for="notes">Notes</label>
            <textarea id="notes" [(ngModel)]="vendor.notes"></textarea>
          </div>
        </div>

        <div class="input-set input-set-percentage">
          <div>
            <label for="percentage">Percentage</label>
            <input type="number" id="percentage" [(ngModel)]="vendor.percentage" />
          </div>
        </div>

        <div class="buttons">
          <div class="button-full" (click)="viewRetailProducts()">View Retail Products</div>
          <div class="button-full" (click)="viewVendorProducts()">View Vendor Products</div>
        </div>
        
        <div class="buttons">
          <div *ngIf="!vendor.saving" class="button-full green" (click)="saveVendorDetail()">Save Vendor</div>
          <div *ngIf="!vendor.saving" class="button-full red" (click)="deleteVendor()">Delete Vendor</div>
        </div>

      </div>
    </div>
  </section>
</div>
