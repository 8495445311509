import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, of } from 'rxjs';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { ClientService } from '../../../services/client/client.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { ScreenService } from '../../../services/screen/screen.service';
import { LocationService } from '../../../services/location/location.service';
import { MessageService } from '../../../services/message/message.service';
import packageJson from '../../../../../package.json';

// import { MessagingComponent } from '../messaging/messaging.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  clientConfig;

  activeScreen;
  screens;

  activeLocation;
  locations;
  locationSearchText;

  screensMenuOpen;
  locationsMenuOpen;
  supportMenuOpen;
  userMenuOpen;

  user;

  canCommunicate;
  messagingRef;
  messageCount;
  summaryUpdated: Subscription;

  public version: string = packageJson.version;

  @ViewChild('locationSearch') locationSearch: ElementRef;

  private activeScreenSet: Subscription;
  private screensUpdated: Subscription;
  private permissionsLoaded: Subscription;

  constructor(
    private clientService: ClientService,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private screenService: ScreenService,
    private locationService: LocationService,
    private router: Router,
    private route: ActivatedRoute,
    // private messageService: MessageService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    console.log('Header INIT');
    this.clientConfig = this.clientService.getConfig();

    if (this.clientConfig.logoUrl) {
      console.log('UPDATE LOGO SOURCE');
      this.clientConfig.logoUrl = this.clientConfig.logoUrl.replace("white", "black");
    }

    this.activeScreen = this.screenService.getActiveScreen();
    this.screens = this.screenService.getScreens();

    let locations = this.locationService.getLocations();
    if (locations) {
      this.locations = locations.filter(location => {
        return location.type === 'retail' || location._id === null;
      });

      console.log('locations: ', this.locations);
    }

    this.locationSearchText = this.locationService.getSearchText();

    this.locationService.locationsSetObservable().subscribe(locations => {
      if (locations) {
        this.locations = locations.filter(location => {
          return location.type === 'retail' || location._id === null;
        });
      } else {
        this.locations = [];
      }
    });

    this.activeLocation = this.locationService.getActiveLocation();
    // this.messageService.monitorSummary(true);  // TODO: Re-enable conditionally

    this.locationService.activeLocationSetObservable().subscribe(location => {
      this.activeLocation = location;
      // this.messageService.monitorSummary(true);  // TODO: Re-enable conditionally
    });

    // Subscribe to activeScreen changes
    this.activeScreenSet = this.screenService.activeScreenSetObservable().subscribe(activeScreen => {
      // console.log('header notified of activeScreenSet');
      this.activeScreen = activeScreen;
    });

    // Subscribe to screens changes
    this.screensUpdated = this.screenService.screensUpdatedObservable().subscribe(screens => {
      this.updateScreens(screens);
    });

    // Update user info when permissions are loaded
    this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(() => {
      this.permissionsAreLoaded();
    });

    if (this.authorizationService.permissionsAreLoaded()) {
      this.permissionsAreLoaded();
    }

    // If the user is already available, set it here
    this.user = this.authorizationService.getUser();
  }

  ngOnDestroy() {
    this.activeScreenSet.unsubscribe();
    this.screensUpdated.unsubscribe();
    this.permissionsLoaded.unsubscribe();

    if (this.summaryUpdated) {
      this.summaryUpdated.unsubscribe();
    }
  }

  permissionsAreLoaded() {
    this.user = this.authorizationService.getUser();

    // See if active user can communicate
    // if (this.authorizationService.checkPermission('location.communicate', 'any')) {
      // this.canCommunicate = true;

      // this.messageService.monitorSummary(true);

      // Monitor for unread messages
      // this.summaryUpdated = this.messageService.summaryUpdatedObservable().subscribe(summary => {
      //   this.messageCount = summary.messageCount;
      // });
    // }
  }

  updateScreens(screens) {
    this.screens = screens;
  }

  setActiveLocation(location) {
    this.locationService.setActiveLocation(location);
  }

  searchClick(event) {
    event.stopPropagation();
  }

  searchLocations(event: any) {
    this.locationSearchText = event.target.value;
    this.locationService.setSearchText(this.locationSearchText);
  }

  clearSearch() {
    this.locationSearchText = null;
    this.locationService.setSearchText(this.locationSearchText);
    this.locationSearch.nativeElement.focus();
  }

  toggleScreensMenu() {
    this.locationsMenuOpen = false;
    this.userMenuOpen = false;
    this.supportMenuOpen = false;
    this.screensMenuOpen = !this.screensMenuOpen;
  }

  toggleLocationsMenu() {
    this.screensMenuOpen = false;
    this.userMenuOpen = false;
    this.supportMenuOpen = false;
    this.locationsMenuOpen = !this.locationsMenuOpen;
    if (this.locationsMenuOpen && this.locationSearch) {
      setTimeout(() => {
        this.locationSearch.nativeElement.focus();
      }, 0);
    }
  }

  toggleSupportMenu() {
    this.screensMenuOpen = false;
    this.locationsMenuOpen = false;
    this.userMenuOpen = false;
    this.supportMenuOpen = !this.supportMenuOpen;
  }

  toggleUserMenu() {
    this.screensMenuOpen = false;
    this.locationsMenuOpen = false;
    this.supportMenuOpen = false;
    this.userMenuOpen = !this.userMenuOpen;
  }

  signOut() {
    this.authenticationService.signout();
  }

  launchMessaging() {
    console.log('launchMessaging: ');

    console.log(this.router.url);
    let pathArray = this.router.url.split("/");
    console.log(pathArray);

    switch (pathArray[1]) {
      case 'marketing':
        this.router.navigate(['marketing', 'dashboard', 'conversations']);
        break;

      case 'reports':
        this.router.navigate([pathArray[1], pathArray[2], 'conversations']);
        break;

      case 'virtual-terminal':
        this.router.navigate(['virtual-terminal', 'conversations']);
        break;

      default:
        this.router.navigate(['reports', 'credit-card-transactions', 'conversations']);
    }



    // this.messagingRef = this.dialog.open(MessagingComponent, {
    //   panelClass: 'full-screen-dialog'
    // });
    //
    // this.messagingRef.afterClosed().subscribe(newId => {
    //   console.log('modal closed: ', newId);
    //
    // });
  }
}
